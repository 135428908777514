import { Button } from "@headlessui/react";
import SignInDialog from "@src/app/SignIn/SignInDialog";
import { getStartedTitle } from "@src/constants";
import { RootState } from "@src/store/store";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function GetStarted() {
  const [isOpen, setIsOpen] = useState(false);
  const wallet = useSelector((state: RootState) => state.wallet);
  const router = useRouter();

  const onSignIn = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const handleButton = () => {
    if (wallet.instance !== null) {
      router.push("/drep/mint");
    } else {
      onSignIn();
    }
  };
  return (
    <div className="max-w-[1440px] flex justify-center items-center flex-col w-full px-[50px] sm:px-[120px]">
      <div className="flex flex-col justify-center items-center w-full gap-[64px] rounded-[24px] bg-getstarted-background bg-cover bg-no-repeat sm:py-[96px] py-[50px] ">
        <p className="text-white h2 text-center font-bold max-w-[930px]">
          {getStartedTitle}
        </p>
        <Button
          onClick={handleButton}
          className="text-neutral-800 rounded-[8px] body1 font-semibold px-6 py-[18px] bg-button-gradient"
        >
          Get Started
        </Button>
      </div>
      <SignInDialog isOpen={isOpen} onClose={onClose} />
    </div>
  );
}
