import { CIP30Provider } from "kuber-client/types";
import { useState } from "react";

declare global {
  interface Window {
    cardano: Record<string, CIP30Provider>;
  }
}

export function listProviders(): CIP30Provider[] {
  const pluginMap = new Map();

  if (typeof window === "undefined" || !window.cardano) {
    return [];
  }

  Object.keys(window.cardano).forEach((x) => {
    const plugin: CIP30Provider = window.cardano[x];

    if (plugin && typeof plugin.enable === "function" && plugin.name) {
      pluginMap.set(plugin.name, plugin);
    }
  });
  const providers = Array.from(pluginMap.values());
  // yoroi doesn't work (remove this after yoroi works)
  return providers.filter((x) => x.name != "yoroi");
}
